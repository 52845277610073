/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '@cadexchanger/react-component-library-new';
import 'flickity/css/flickity.css';
import 'aos/dist/aos.css';

import './src/styles/style.scss';
import './src/styles/main-products.scss';
import './src/styles/non-flkt-carousels.scss';
import UseQueryParamsWrapper from './src/components/UseQueryParamsWrapper';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <UseQueryParamsWrapper>
      {element}
    </UseQueryParamsWrapper>
  </ThemeProvider>
);
