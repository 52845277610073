import React from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReachAdapter } from 'use-query-params/adapters/reach';

export interface UseQueryParamsWrapperProps {
  children: React.ReactNode;
}

export default function UseQueryParamsWrapper(
  {
    children,
  }: UseQueryParamsWrapperProps,
): JSX.Element {
  return (
    <QueryParamProvider adapter={ReachAdapter}>{children}</QueryParamProvider>
  );
}
